@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import '../../../../libs/ui/src/index.css';

@import 'tailwindcss/utilities';

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
}

@keyframes animate-voucher {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(5deg);
  }
}

.animate-voucher {
  animation: animate-voucher 0.5s cubic-bezier(1, 0.885, 0.32, 1.275) forwards;
}
